import React, { Component } from 'react';
const encode = (data) => {
    return Object.keys(data)
   .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
   .join("&");
  }


class NewsletterForm extends Component {

 constructor(props) {
      super(props);
      this.state = {  email: "" };
    }

handleSubmit = e => {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact", ...this.state })
      })
        .then(() => document.getElementById("message").innerHTML= "Votre demande a bien été prise en compte. A bientôt !")
        .catch(error => document.getElementById("message").innerHTML= "Une erreur s'est produite. Veuillez réessayer.");

      e.preventDefault();
    };

    handleChange = e => this.setState({ [e.target.name]: e.target.value });


  render() {
    const {  email } = this.state;
      return (
          <div className="mailinglist">
            <form id="newsletter" onSubmit={this.handleSubmit}>
                <p>
                <label>
                <input type="email" name="email" required="true" value={email} onChange={this.handleChange} placeholder="votreadresse@mail.fr" />
                </label>
            </p>
                <div data-netlify-recaptcha="true"></div>
            <p>
                <button type="submit">M'inscrire à la newsletter</button>
            </p>
            </form>
            <p id="message"></p>
          </div>
    )
  }
}
export default NewsletterForm;