import React from 'react';
import './App.css';
import Logo from './logo-actm.png'
import NewsletterForm from "./newsletterForm"
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={Logo} alt="Logo Auto Contrôle" />
        <h1>Réouverture lundi 13juin 2022</h1>
        <p>Un <strong>contre temps indépendant de notre volonté</strong> nous pousse à <strong>décaler l'ouverture</strong> de notre établissement.
Nous pourrons vous accueillir dès le lundi 13 juin 2022. Prenez rendez-vous dès maintenant pour votre contrôle technique en appelant directement le <a href="tel:+33381905522" title="Prendre rendez-vous">03 81 90 55 22</a>.<br/>
Pour ceux qui auraient pris rendez-vous avant, nous vous recontacterons afin que nous puissions définir une autre date.
Nous présentons nos plus sincères excuses pour la gêne occasionnée.<br/>
L'équipe d'Auto Contrôle</p>

        <p>Pour être tenu.e informé.e des prochaines actualités et offres, inscrivez-vous à notre newsletter.</p>
        <NewsletterForm />
      </header>
    </div>
  );
}

export default App;
